@import "../../responsive.scss";

.about-us-image {
  width: 100%;
  background-color: #f0f0f0;

  .about-us-image-desktop {
    width: 100%;
    height: auto;
    display: block;

    @include sm {
      display: none;
    }
  }

  .about-us-image-phone {
    display: none;

    @include sm {
      display: block;
      width: 100%;
      height: auto;
    }
  }
}

.about-us-our-story {
  margin-top: 80px;

  @include md {
    margin-top: 60px;
  }

  @include sm {
    margin-top: 30px;
  }

  h2 {
    color: #e96c4c;
    padding-bottom: 1rem;
  }

  .about-us-our-story-content {
    background-color: #FAF6F6;
    padding: 2rem 0;

    .about-us-our-story-content-top {
      display: flex;
      justify-content: space-between;
      gap: 2rem;
      margin-bottom: 2rem;

      @include sm {
        flex-direction: column;
      }

      img {
        flex: 2;
        background-color: #FAF6F6;
        height: 340px;

        @include sm {
          flex: 1;
          height: 340px;
        }
      }

      p {
        flex: 4;
        padding: 2rem 0;
        padding-right: 4rem;
        font-weight: 500;
        line-height: 1.5;
        font-size: 18px;

        @include sm {
          padding: 0;
          flex: 1;
          font-size: 14px;
        }

      }
    }

    .about-us-our-story-content-bottom {
      p {
        font-style: italic;
        font-size: clamp(0.5rem, 3vw, 1rem);
        line-height: 1.4;
      }
    }
  }
}

.milestones-container {
  margin-top: 80px;

  @include md {
    margin-top: 65px;
  }

  @include sm {
    margin-top: 50px;
  }

  h2 {
    color: #e96c4c;
    padding-bottom: 1rem;
  }

  p {
    font-weight: 500;
    line-height: 1.5;
  }

  img {
    width: 100%;
    height: auto;
    margin-top: 3rem;
  }
}

.our-team {
  background-color: #FAF6F6;
  padding: 2rem 0;
  margin-top: 80px;

  @include md {
    margin-top: 65px;
  }

  @include sm {
    margin-top: 50px;
  }

  h2 {
    color: #e96c4c;
  }

  p {
    padding: 1rem 0;
    font-weight: 500;
    line-height: 1.5;
  }
}

.backed-by {
  margin-top: 80px;

  @include md {
    margin-top: 65px;
  }

  @include sm {
    margin-top: 50px;
  }

  h2 {
    color: #e96c4c;
  }

  p {
    padding: 1rem 0;
    font-weight: 500;
    line-height: 1.5;
  }

  .backed-by-logos {
    padding-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 1rem;
    justify-content: flex-start;
    align-items: center;

    img {
      width: 150px;
      height: auto;

      @include sm {
        width: 100px;
      }
    }
  }
}

.supported-by {
  margin: 80px 0;

  @include md {
    margin-top: 65px 0;
  }

  @include sm {
    margin-top: 50px 0;
  }

  h5 {
    color: #e96c4c;
    display: flex;
    align-items: center;
    padding-bottom: 1rem;
  }

  img {
    width: 140px;
    height: auto;

    @include sm {
      width: 100px;
      height: auto;
    }
  }
}