@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  font-family: "Poppins", "Roboto", sans-serif;
  background-color: #fff;
  font-weight: 400;
  line-height: auto;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: inherit;
}

h1 {
  font-size: clamp(1.5rem, 6vw, 3rem);
}

h2 {
  font-size: clamp(1.25rem, 4vw, 2.5rem);
  font-weight: 600;
}

h3 {
  font-size: clamp(1.1rem, 3.5vw, 2rem);
}

h4 {
  font-size: clamp(1rem, 3vw, 1.5rem);
}

h5 {
  font-size: clamp(0.9rem, 2.5vw, 1.25rem);
  font-weight: 600;
}

h6 {
  font-size: clamp(0.8rem, 2vw, 1rem);
}
