@import "../../responsive.scss";

.header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 999;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

nav {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 0;

  @include sm {
    height: 40px;
    padding: 0;
  }

  a {
    @include sm {
      display: none;
    }
  }

  .left {
    flex: 3;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 50px;

    div {
      display: flex;
      justify-content: space-between;
      gap: 2rem;

      .nav-link {
        text-decoration: none;
        color: #000;
        padding: 0 4px 5px 4px;
        transition: all 0.3s ease;
      }

      .nav-link:hover {
        color: #e96c4c;
        transform: scale(1.02);
      }

      .active {
        font-weight: 500;
        border-bottom: 3px solid #e96c4c;
        color: #e96c4c;
      }
    }

    .logo {
      font-weight: bold;
      font-size: 20px;
      display: flex;
      align-items: center;

      img {
        width: 140px;

        @include sm {
          width: 100px;
        }
      }

      span {
        @include md {
          display: none;
        }

        @include sm {
          display: initial;
        }
      }
    }
  }

  .right {
    flex: 2;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    display: none;

    @include md {
      background-color: transparent;
    }

    a {
      padding: 12px 24px;
      margin: 20px;
    }


    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 20px;
    }

    span {
      @include sm {
        display: none;
      }
    }

    .menuIcon {
      display: none;
      z-index: 999;

      @include sm {
        display: inline;
        margin-right: -10px;
      }
    }

    .menu {
      position: absolute;
      top: 0;
      right: -50%;
      background-color: #f5f5f5;
      color: #333;
      height: 100vh;
      width: 50%;
      transition: all 1s ease;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 24px;

      &.active {
        right: 0;
      }

      @include sm {
        a {
          display: initial;
          font-size: 18px;
          padding: 0;
        }
      }
    }
  }

  .right {
    @include sm {
      display: inline-flex;
      ;
    }
  }
}