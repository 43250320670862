@import "../../responsive.scss";

.layout {
  min-height: 85vh;
  height: auto;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;

  @include xl {
    max-width: 1200px;
  }

  @include lg {
    max-width: 900px;
  }

  @include md {
    max-width: 100%;
  }

  @include sm {
    max-width: 100%;
  }

  .content {
    height: auto;
    overflow: scroll;
    margin-top: 60px;

    @include sm {
      margin-top: 40px;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;

  }
}