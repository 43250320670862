@import "../../responsive.scss";

.footer-container {
    padding: 2rem 0;
    background: #202D46;

    @include md {
        padding: 1.5rem 0;
    }

    @include sm {
        font-size: 12px;
        padding: 1rem 0;
    }


    .footer-content-container {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        text-align: left;

        @include sm {
            flex-direction: column;
        }

        >div {
            flex: 1;
            color: #fff;

            img {
                height: 35px;
                width: auto;
                margin-top: -5px;
            }

            .social-icon {
                display: flex;
                justify-content: space-evenly;
                width: 30.5%;
                margin-top: 1rem;

                .icons {
                    color: #fff;
                    font-size: 25px;
                    transition: all 0.3s ease-in;
                }

                .icons:hover {
                    transform: scale(1.2);
                    cursor: pointer;

                }
            }

            h4 {
                color: #e96c4c;
                font-weight: 500;
            }

            .useful-links {
                margin-top: 10px;
                display: flex;
                flex-direction: column;
                font-size: 14px;
                gap: 2px;

                span {

                    a:hover {
                        text-decoration: underline;
                        color: #e96c4c;
                        cursor: pointer;
                    }
                }
            }


            p {
                margin-top: 10px;
                font-size: 14px;

                a:hover {
                    text-decoration: underline;
                }
            }
        }


    }

    .footer-bottom {
        margin-top: 1rem;
        border-top: 2px solid #D9D5D533;
        padding-top: 1rem;
        font-size: 14px;

        .policy-and-copyright-text {
            color: #fff;
            display: flex;
            justify-content: space-between;

            .policy-text {
                display: flex;
                gap: 10px;
            }

            @include sm {
                gap: 10px;
                text-align: left;
            }

            a:hover {
                text-decoration: underline;
            }
        }
    }
}